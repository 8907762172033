import axios from 'axios';

class Auth {
    constructor() {
        this.authenticated = false
    }

    checkLoggedIn(cb) {
        let token = localStorage.getItem('token');
        axios.get('/users/user', token).then(res => {
            if (res.data.authData) {
                this.authenticated = true;
                cb(res.data.authData.user);
            } else {
                cb(false);
            }
        });
    }

    login(logInfo, cb) {
        try {
            axios.post('/users/login', {
                username: logInfo.username,
                password: logInfo.password
            }).then(async response => {
                if (response.status === 200 && response.data !== "Wrong password" && response.data !== "cannot find user") {
                    if (response.data.token !== null) {
                        localStorage.setItem('token', response.data.token);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                        this.authenticated = true;
                        cb(true);
                    } else {

                        cb(false);
                    }
    
                } else {
                    cb(false);
                }
            });
        }
        catch(err) {
            console.err('err');
            cb(false);
        }
    }


    logout() {
        localStorage.removeItem('token');
        this.authenticated = false;
    }

    isAuthenticated() {
        return this.authenticated;
    }
}
export default new Auth();